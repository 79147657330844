<template>
  <div>
    <b-card
      no-body
      class="liveinfo-form"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="10"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              v-model="query.askStatus"
              :options="codes.askStatus"
              class="el-def"
              placeholder="답변여부"
              :reduce="option => option.code"
              @input="refetchData()"
            />
            <b-form-datepicker
              v-model="query.createDateFr"
              class="el-def"
              placeholder="등록일(Fr)"
            />
            <label class="ml-25 mr-50">~</label>
            <b-form-datepicker
              v-model="query.createDateTo"
              class="el-def"
              placeholder="등록일(To)"
            />
            <b-form-input
              v-model="query.userId"
              class="el-def"
              placeholder="작성자ID"
              maxlength="30"
              @keyup.enter="refetchData()"
            />
            <b-form-input
              v-model="query.filterStr"
              class="el-def"
              placeholder="검색어"
              maxlength="30"
              @keyup.enter="refetchData()"
            />
          </b-col>
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="primary"
              class="btn-search"
              @click.prevent="refetchData()"
            >
              조회
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card
      no-body
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start"
          >
            <h5 class="mb-0 ml-50">
              문의 건수 {{ askCount }}건 / 답변 건수 {{ answerCount }}건
            </h5>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="outline-dark"
              class="btn-search"
              @click.prevent="excelDownload()"
            >
              <feather-icon
                icon="DownloadIcon"
                class="mr-50"
              />
              <span>다운로드</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refAskTable"
        primary-key="id"
        :items="fetchMemberAsks"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDesc"
        responsive
        sticky-header
        selectable
        select-mode="single"
        class="position-relative"
      >
        <template #cell(title)="data">
          <a
            href="#"
            class="underline"
            @click.prevent="onSelectedAsk(data.item)"
          >{{ data.value }}</a>
        </template>
        <template #cell(createDate)="data">
          <span class="text-nowrap">
            {{ data.value | $dateFormatter('YYYY-MM-DD HH:mm') }}
          </span>
        </template>
        <template #cell(status)="data">
          <span :class="{'text-primary': data.value === '답변대기', 'text-danger': data.value === '답변완료'}">
            {{ data.value }}
          </span>
        </template>
      </b-table>
    </b-card>
    <!-- [팝업] 문의 확인 및 답변-->
    <b-modal
      v-model="askPopupShow"
      cancel-variant="outline-secondary"
      centered
      ok-title="저장"
      cancel-title="닫기"
      size="lg"
      title="1:1 문의관리"
      no-close-on-esc
      no-close-on-backdrop
      @ok.prevent="saveAnswer()"
    >
      <div class="p-1">
        <b-row>
          <b-col
            cols="12"
            md="12"
            class="d-flex justify-content-end"
          >
            <label>
              작성자ID: {{ askData.userId }}
            </label>
          </b-col>
        </b-row>
        <b-row class="mt-25">
          <b-col
            cols="12"
            md="12"
            class="d-flex justify-content-start"
          >
            <h5 style="word-break: break-all;">
              {{ askData.title }}
            </h5>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col
            cols="12"
            md="12"
            class="d-flex justify-content-start"
          >
            <h5 style="word-break: break-all;">
              {{ askData.content }}
            </h5>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <img
              v-for="item in askData.attachFileList"
              :key="item.fileId"
              :src="item.filePath"
              style="width: 100%;"
            >
          </b-col>
        </b-row>
      </div>
      <b-row
        style="border-top: 1px solid #ebe9f1;"
      >
        <b-col
          cols="12"
          md="12"
          class="mt-1"
        >
          <h6>답변 내용</h6>
        </b-col>
        <b-form-textarea
          v-model="askData.answer"
          placeholder="답변 내용 입력"
          rows="8"
          maxlength="500"
        />
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import axios from '@axios'
import dayjs from 'dayjs'
import { ref, onMounted } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import ExcelJS from "exceljs"
import { saveAs } from "file-saver"

export default {
  components: {
    vSelect,
  },

  setup() {
    onMounted(() => {
      fetchCodes()
    })

    const toast = useToast()

    // 1:1 문의관리 팝업 표시
    const askPopupShow = ref(false)
    const askData = ref({
      id: null,
      title: null,
      content: null,
      answer: null,
    })

    // Query Data
    const query = ref({
      askStatus: null,
      createDateFr: dayjs().add(-3, 'month').format('YYYY-MM-DD'),
      createDateTo: dayjs().format('YYYY-MM-DD'),
      userId: null,
      filterStr: null,
    })
    // Query Data End.

    // Main Table Config
    const list = ref([])
    const refAskTable = ref(null)
    const sortBy = ref('id')
    const isSortDesc = ref(true)
    const totalRecords = ref(0)
    const askCount = ref(0)
    const answerCount = ref(0)
    const tableColumns = [
      {
        key: 'id', label: 'Id', sortable: true, thStyle: { width: '7%' },
      },
      { key: 'title', label: '문의 제목', sortable: true },
      {
        key: 'userName', label: '작성자', sortable: true, thStyle: { width: '10%' },
      },
      {
        key: 'createDate', label: '문의 등록일시', sortable: true, thStyle: { width: '13%' },
      },
      {
        key: 'status', label: '답변 여부', sortable: true, thStyle: { width: '10%' },
      },
      {
        key: 'answerUserName', label: '담당자', sortable: true, thStyle: { width: '10%' },
      },
    ]

    const refetchData = () => {
      refAskTable.value.refresh()
    }

    const onSelectedAsk = item => {
      fetchAsk(item.id)
    }
    // Main Table Config End.

    // Set Codes
    const codes = ref({
      askStatus: [],
    })

    const fetchCodes = () => {
      axios.get('/fa/ask-history/codes')
        .then(rs => {
          const { askStatus } = rs.data

          codes.value.askStatus = askStatus
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '코드 데이터를 불러오는데 실패하였습니다.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    // Set Codes End.

    const fetchAsk = id => {
      axios.get(`/fa/ask-history/ask/${id}`)
        .then(rs => {
          askData.value = rs.data
          askPopupShow.value = true
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '데이터 조회에 실패하였습니다.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const fetchMemberAsks = (ctx, callback) => {
      axios.post('/fa/ask-history/ask/list', {
        search: {
          askStatus: query.value.askStatus,
          createDateFr: query.value.createDateFr ? `${query.value.createDateFr}T00:00:00` : null,
          createDateTo: query.value.createDateTo ? `${query.value.createDateTo}T23:59:59` : null,
          userId: query.value.userId,
          filterStr: query.value.filterStr,
        },
        sort: {
          predicate: sortBy.value,
          reverse: isSortDesc.value,
        },
      })
        .then(rs => {
          const { items, totalRecord } = rs.data

          callback(items)

          list.value = items
          askCount.value = totalRecord
          answerCount.value = items.filter(e => e.answerUserName !== null).length
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '데이터 조회에 실패하였습니다.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const saveAnswer = () => {
      if (!askData.value.answer || askData.value.answer.length < 2) {
        toast({
          component: ToastificationContent,
          props: {
            title: '답변 내용을 입력하세요.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })

        return
      }

      axios.patch('/fa/ask-history/ask/answer', {
        id: askData.value.id,
        answer: askData.value.answer,
      })
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '답변 등록 완료',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })

          askPopupShow.value = false

          refetchData()
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '답변 등록 과정에서 오류가 발생하였습니다.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    // 내부망에서만 다운로드 가능
    const excelDownload = async () => {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet("sheet1")

      worksheet.columns = [
        { header: "문의 제목", key: "title", width: 30 },
        { header: "문의 내용", key: "content", width: 30 },
        { header: "작성자", key: "userName", width: 10 },
        { header: "문의 등록일시", key: "createDate", width: 20 },
        { header: "답변 여부", key: "status", width: 12 },
        { header: "답변 내용", key: "answer", width: 30 },
        {
          header: "담당자",
          key: "answerUserName",
          width: 10,
        },
      ]

      list.value.map(e => worksheet.addRow({
        title: e.title,
        content: e.content,
        userName: e.userName,
        createDate: dayjs(e.createDate).format('YYYY-MM-DD HH:mm'),
        status: e.status,
        answer: e.answer,
        answerUserName: e.answerUserName,
      }))

      worksheet.eachRow({ includeEmpty: true }, (row, number) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (number === 1) {
            // eslint-disable-next-line no-param-reassign
            cell.font = {
              bold: true,
            }

            // eslint-disable-next-line no-param-reassign
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'D3D3D3' },
            }
          }

          // eslint-disable-next-line no-param-reassign
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }
        })
      })

      const mimeType = {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }

      const buffer = await workbook.xlsx.writeBuffer()

      const blob = new Blob([buffer], mimeType)

      saveAs(blob, "일대일문의내역_리스트.xlsx")
    }

    return {
      query,
      codes,
      askData,
      askPopupShow,
      tableColumns,
      refAskTable,
      sortBy,
      isSortDesc,
      refetchData,
      totalRecords,
      fetchMemberAsks,
      onSelectedAsk,
      saveAnswer,
      askCount,
      answerCount,
      excelDownload,
      list,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
